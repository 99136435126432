<template>
  <a-form-model
    class="search-form"
    ref="form"
    :model="form"
    :label-col="{span: 4}"
    :wrapper-col="{span: 20}"
  >
    <a-row :gutter="60">
      <a-col :span="8">
        <a-form-model-item label="经销商名称" prop="dealer_name">
          <a-input
            v-model.trim="form.dealer_name"
            placeholder="请输入"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="统计平台" prop="platform_type">
          <a-select
            v-model="form.platform_type"
          >
            <a-select-option :value="1">抖音</a-select-option>
            <a-select-option :value="2">快手</a-select-option>
            <a-select-option :value="3">懂车帝</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="统计时间" prop="time">
          <a-range-picker
            v-model="form.time"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            :allow-clear="false"
            :placeholder="['开始时间', '结束时间']"
            style="width:100%;"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item label="经销商分组" prop="account_group_id">
          <a-select
            v-model="form.account_group_id"
            :default-active-first-option="false"
            :filter-option="filterOption"
            show-search
            allow-clear
          >
            <a-select-option
              v-for="item in groupList"
              :key="item.group_id"
              :value="item.group_id"
            >
              {{ item.group_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="8">
        <a-form-model-item :wrapper-col="{offset: 4, span: 20}">
          <a-space>
            <a-button type="primary" @click="handleSearch">查询</a-button>
            <a-button @click="handleReset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-col>
    </a-row>
  </a-form-model>
</template>

<script>
const columns = [
  {
    title: '经销商信息',
    scopedSlots: { customRender: 'dealerInfo' }
  },
  {
    title: '风火轮帐号信息',
    scopedSlots: { customRender: 'accountInfo' }
  },
  {
    title: '课程学习进度',
    scopedSlots: { customRender: 'sectionsInfo' }
    
  },
  {
    title: '勋章获取情况',
    scopedSlots: { customRender: 'medalInfo' }
  },
  {
    title: '认证情况',
    scopedSlots: { customRender: 'awardedInfo' }
    
  },
  {
    title: '操作',
    width: 150,
    scopedSlots: { customRender: 'operation' }
  }
]

export default {
  name: 'SearchForm',
  props: {
    form: {
      type: Object,
      default () {
        return {}
      }
    },
    groupList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      columns
    }
  },
  methods: {
    filterOption (inputValue, option) {
      return option.componentOptions.children[0].text.indexOf(inputValue) > -1
    },
    handleSearch () {
      this.$emit('search')
    },
    handleReset () {
      this.$refs.form.resetFields()
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 10px;
}
</style>
