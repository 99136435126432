<template>
  <div class="data-table">
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :rowKey="(record, index) => index"
      :pagination="false"
    >
      <div slot="dealerInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>经销商名称：</span></div>
          <div>{{ record.dealer_name || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>品牌：</span></div>
          <div>{{ record.principal_names || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>大区/省/市：</span></div>
          <div>{{ record.area_name || "--" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>分组：</span></div>
          <div>{{ record.account_group_names || "--" }}</div>
        </div>
      </div>
      <div slot="statisticalInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>统计平台：</span></div>
          <div>{{ record.platform_name || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>统计时间：</span></div>
          <div>{{ record.time || "0" }}</div>
        </div>
      </div>
      <div slot="moduleInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>使用模板：</span></div>
          <div>{{ record.template_total || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>生成成片：</span></div>
          <div>{{ record.aweme_total || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>发布视频：</span></div>
          <div>{{ record.publish_total || "0" }}</div>
        </div>
      </div>
      <div slot="moduleTypeInfo" slot-scope="text, record">
        <div class="row-box box-1">
          <div><span>视频播放次数：</span></div>
          <div>{{ record.play_volume || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>视频点赞数：</span></div>
          <div>{{ record.digg_count || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>视频分享数：</span></div>
          <div>{{ record.share_count || "0" }}</div>
        </div>
        <div class="row-box box-1">
          <div><span>视频评论数：</span></div>
          <div>{{ record.comment_count || "0" }}</div>
        </div>
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="handleActionClick('detail', record)">详情</a>
      </div>
    </a-table>
    <!-- 分页配置 -->
    <base-pagination
      :currentPage="pagination.current"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: '经销商信息',
    scopedSlots: { customRender: 'dealerInfo' }
  },
  {
    title: '统计平台',
    scopedSlots: { customRender: 'statisticalInfo' }
  },
  {
    title: "模板信息",
    scopedSlots: { customRender: "moduleInfo" }

  },
  {
    title: '模板转化效果',
    scopedSlots: { customRender: 'moduleTypeInfo' }
  },
  {
    title: '操作',
    width: 150,
    align: 'center',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'DataTable',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    pagination: {
      type: Object,
      default () {
        return {
          current: 1,
          pageSize: 10,
          total: 0
        }
      }
    }
  },
  data () {
    return {
      columns
    }
  },
  methods: {
    handleActionClick (type, record) {
      this.$emit('action-click', type, record)
    },
    handlePaginationChange (current, pageSize) {
      this.$emit('pagination-change', current, pageSize)
    }
  }
}
</script>

<style lang="scss" scoped>
.row-box {
  display: flex;
  margin-bottom: 5px;

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-weight: bold;

    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  & > div:last-child {
    flex: 1;
    min-width: 60px;
  }
}

.box-1 {
  & > div:first-child {
    width: 100px;
  }
}
</style>
