<template>
  <a-card class="page-container">
    <search-form
      :form="searchForm"
      :group-list="groupList"
      @search="handleSearch"
      @reset="handleReset"
    />

    <div class="oper-bar">
      <a-button
        type="primary"
        :loading="exporting"
        @click="handleExport"
      >
        导出当前搜索条件数据
      </a-button>
    </div>

    <data-table
      :loading="loading"
      :data="tempList"
      :pagination="pagination"
      @pagination-change="handlePaginationChange"
      @action-click="handleActionClick"
    />
  </a-card>
</template>

<script>
import SearchForm from './components/SearchForm'
import DataTable from './components/DataTable'
import { downloadExcel } from '@/utils'
import https from '@/api/kpi.js'
import activityApi from '@/api/activity'

export default {
  name: 'actTemplateOperAssi',
  components: {
    SearchForm,
    DataTable
  },
  data () {
    return {
      searchForm: {
        dealer_name: '',
        platform_type: '',
        time: [
          this.$moment().subtract(6, 'days').format('YYYY-MM-DD'),
          this.$moment().format('YYYY-MM-DD')
        ],
        account_group_id: ''
      },
      groupList: [],
      loading: false,
      tempList: [{}],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      exporting: false
    }
  },
  created () {
    this.getTempList()
    this.fetchGroupList()
  },
  methods: {
    // 处理查询
    handleSearch () {
      this.pagination.current = 1
      this.getTempList()
    },
    // 处理重置
    handleReset () {
      this.pagination.current = 1
      this.getTempList()
    },
    // 处理分页改变
    handlePaginationChange (current, pageSize) {
      this.pagination.current = current
      this.pagination.pageSize = pageSize
      this.getTempList()
    },
    // 获取模板列表
    async getTempList () {
      this.loading = true
      try {
        const { time, ...restSearchForm } = this.searchForm
        const { current, pageSize } = this.pagination
        const params = {
          activity_id: this.$route.query.activity_id,
          ...restSearchForm,
          start_time: `${time[0]} 00:00:00`,
          end_time: `${time[1]} 23:59:59`,
          page_num: current,
          page_size: pageSize,
        };
        const { code, data, message } = await https.getModuleListList(params)

        if (code === 200) {
          this.tempList = data.list
          this.pagination.total = data.total
          this.loading = false
        } else {
          this.$message.error(message || "获取模板列表失败")
          this.loading = false
        }
      } catch (e) {
        this.loading = false
      }
    },
    // 获取分组列表
    async fetchGroupList () {
      try {
        const params = {
          group_name: ''
        }
        const { code, data, message } = await https.getGroupSelect(params)

        if (code === 200) {
          this.groupList = data
        } else {
          this.$message.error(message || '获取公共分组失败')
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 处理导出
    handleExport () {
      this.exportTempList()
    },
    // 导出模板列表
    async exportTempList () {
      this.exporting = true
      try {
        const { time, ...restSearchForm } = this.searchForm
        const params = {
          activity_id: this.$route.query.activity_id,
          ...restSearchForm,
          start_time: `${time[0]} 00:00:00`,
          end_time: `${time[1]} 23:59:59`,
        }
        const res = await activityApi.exportTempList(params)

        downloadExcel(res, '模板列表数据')
        this.exporting = false
      } catch (e) {
        console.log(e)
        this.exporting = false
      }
    },
    // 处理操作点击
    handleActionClick (type, record) {
      const { platform_type, time } = this.searchForm

      this.$router.push({
        name: 'template-detail',
        query: {
          dealer_id: record.dealer_id,
          platform_type,
          start_time: `${time[0]} 00:00:00`,
          end_time: `${time[1]} 23:59:59`,
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;
}

.oper-bar {
  margin: 10px 0 0;
  text-align: right;
}

.data-table {
  margin: 10px 0 0;
}
</style>
